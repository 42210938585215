import { useState } from 'react';
import 'reactjs-popup/dist/index.css';

const ContactForm = () => {
    const [user, setUser] = useState({} as any);

    const submit2 = (e) => {
        e.preventDefault();
        fetch('https://script.google.com/macros/s/AKfycbxilvvYzjEYWl98FKgr8UEy5XglDdXNl7ZPY4EMkv969ElA6byl8vnqHd2vaA5_oAD9/exec', {
          method: 'POST',
          body: JSON.stringify({ user }),
          headers: { 'Content-Type': 'application/json' },
        })
          .then((res) => res.json())
          .then(() => setUser({ done: true}));
    };

    if (user.done) {
        return(<div> Gelukt</div>);
    }

    return (
        <form
            id='contactform'
            className='gform'
            method='POST'
            onSubmit={(e) => submit2(e)}
            >
            <h4 className='text-center formHeader'>Stuur ons een bericht</h4>
            <div className='form-row'>
                <div className='form-group col-sm-12 col-lg-6 inputPadding'>
                    <input name='name' type='text' className='form-control' placeholder='Naam*' value={user.name} onChange={(e) => setUser({...user, name: e.target.value})}/>
                    <input name='email'  type='text' className='form-control' placeholder='E-mail*' value={user.email} onChange={(e) => setUser({...user, email: e.target.value})} />
                    <input name='phone' type='text' className='form-control' placeholder='Telefoonnummer' value= {user.phone} onChange={(e) => setUser({...user, phone: e.target.value})} />
                </div>
                <div className='form-group col-sm-12 col-lg-6 inputPadding'>
                    <textarea name='message' form='contactform' className='form-control' rows={5} placeholder='Uw bericht' value={user.message} onChange={(e) => setUser({...user, message: e.target.value})} />
                </div>
            </div>
            {/* TODO onClick event implementeren */}
            <button type='submit' className='btn btn-primary mb-2' style={{backgroundColor: '#000763', borderColor: '#000763', display: 'flex', margin: 'auto'}}>Verstuur</button>
        </form>
); };

export default ContactForm;