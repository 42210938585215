
import React from 'react';
// import moment from 'moment';

// The store
import { connect } from 'react-redux';

import LOC from './../Localization/Translations';
import './standardPageStyles.css';

const Footer = require('./../Components/Footer/Footer.web').default;

class StandardPageWeb extends React.Component<any> {

    private loc = new LOC();
    // private keyComb: string = '';

    public state = {
        countDown: true,
        countDownDays: '',
        countDownHours: '',
        countDownMinutes: '',
        countDownSeconds: '',
        width: '0px',
    };
/*
    private handleKeyPress = (event) => {

        /*
        if (event.key !== 'w' && event.key !== 'o') {
            return this.keyComb = '';
        }

        this.keyComb = this.keyComb + event.key;

        if (this.keyComb === 'wow') {
            this.keyComb = '';

            setInterval(() => {
                const eventTime = moment('2021-11-01').unix();
                const currentTime = moment().unix();
                const diffTime = eventTime - currentTime;
                let duration: any = moment.duration(diffTime * 1000, 'milliseconds');
                const interval = 1000;

                duration = moment.duration(duration - interval, 'milliseconds');

                this.setState({ width: '250px', countDownDays: duration.days(), countDownHours: duration.hours(), countDownMinutes: duration.minutes(), countDownSeconds: duration.seconds() });
            }, 1000);

            this.setState({ countDown: true });
        }
}
            */


    public render() {

        this.loc.setLanguage(this.props.app.current);

        return (
            <div tabIndex={0}>

                {this.state.countDown &&
                    <div className='popup' onClick={() => (window as any).Usersnap.show('a36520f8-329d-4878-89fa-1711d35bd40d')} style={{ width: this.state.width }}>
                        <span className='firstTimer timer'>{this.state.countDownDays}</span>
                        <span className='timer'>{this.state.countDownHours}</span>
                        <span className='timer'>{this.state.countDownMinutes}</span>
                        <span className='timer'>{this.state.countDownSeconds}</span>
                    </div>
                }


                    <div>
                        {React.cloneElement(this.props.children as JSX.Element, { app: this.props.app })}
                        <Footer navigation={this.props.navigation} />
                    </div>

            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    const { app } = state;
    return { app };
};

export default connect(mapStateToProps)(StandardPageWeb);
