import React from 'react';
import { SceneView } from '@react-navigation/core';
import './styles.css';
// import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import LOC from '../../Localization/Translations';
const lang = LOC.getLanguage();
const StandardPageTemplate = require('./../../Templates/StandardPage').default;
export default class HamburgerMenu extends React.PureComponent<any> {

	public state = {
		width: 0,
	};

	private collapseSize: number = 992;

	public componentDidMount(): void {
		this.setState({width: window.innerWidth});
		window.addEventListener('resize', () => {
			this.setState({
				width: window.innerWidth,
			});
		});
	}

	public render() {
		const { descriptors, navigation } = this.props;
		const activeKey = navigation.state.routes[navigation.state.index].key;
		const descriptor = descriptors[activeKey];

		// @ts-ignore
		return (
			<div>
				<nav className='navbar  sticky-top navbar-expand-lg navbar-light navPadding navbar-background-color'
					 style={{backgroundColor: 'white'}}>
					<div className='container'>
						<a className='navbar-brand cursor-pointer' onClick={() => navigation.navigate('Home')}>{lang.name.split(' ')[0]}<span className='logo-color'> {lang.name.split(' ')[1]}</span></a>
						<button
							className='navbar-toggler'
							type='button'
							data-toggle='collapse'
							data-target='#navbarNavAltMarkup'
							aria-controls='navbarNavAltMarkup'
							aria-expanded='false'
							aria-label='Toggle navigation'>

							<span className='navbar-toggler-icon'></span>
						</button>

						<div className='collapse navbar-collapse pull-right' id='navbarNavAltMarkup'>
							<div className='navbar-nav mr-auto'>
							</div>

							<div className='navbar-nav'>
								<span className='nav-link-custom'
										data-toggle={this.state.width < this.collapseSize ? 'collapse' : ''}
										data-target='#navbarNavAltMarkup'
										onClick={() => navigation.navigate('Home')}>{lang.home}</span>

								{/*<span className='nav-link-custom'
										data-toggle={this.state.width < this.collapseSize ? 'collapse' : ''}
										data-target='#navbarNavAltMarkup'
										style={{ textDecoration: 'line-through'}}
										onClick={() => navigation.navigate('Markets')}>{'Markten'}</span>*/}
								{/*
								<span className='nav-link-custom'
										data-toggle={this.state.width < this.collapseSize ? 'collapse' : ''}
										data-target='#navbarNavAltMarkup'
										onClick={() => navigation.navigate('Services')}>{'Diensten'}</span>
								*/}
								<span className='nav-link-custom'
										data-toggle={this.state.width < this.collapseSize ? 'collapse' : ''}
										data-target='#navbarNavAltMarkup'
										onClick={() => navigation.navigate('Team')}>{'Team'}</span>
								{/*
								<span className='nav-link-custom'
										data-toggle={this.state.width < this.collapseSize ? 'collapse' : ''}
										data-target='#navbarNavAltMarkup'
										onClick={() => navigation.navigate('Projecten')}>{'Thema\'s'}</span>
								*/}

								{/*<span className='nav-link-custom'
										data-toggle={this.state.width < this.collapseSize ? 'collapse' : ''}
										data-target='#navbarNavAltMarkup'
										style={{ textDecoration: 'line-through'}}
										onClick={() => navigation.navigate('JobOpenings')}>{'Werken bij'}</span>
									*/}
								<span className='nav-link-custom'
										data-toggle={this.state.width < this.collapseSize ? 'collapse' : ''}
										data-target='#navbarNavAltMarkup'
										onClick={() => navigation.navigate('Contact')}>{lang.contact}</span>
								{/* <span className='align-self-center ml-3'>
									<LanguageSwitcher />
								</span> */}
							</div>
						</div>
					</div>
				</nav>

				<StandardPageTemplate navigation={navigation}>

					{/* The page is rendered inside the Scenediv */}
					<SceneView
						component={descriptor.getComponent()}
						navigation={descriptor.navigation}
					/>

				</StandardPageTemplate>

			</div>
		);
	}
}
