

import ContactPopup from '../ContactPopup/ContactPopup';
import './styles.css';

interface IImageTextBox {
	imageLeft?: boolean;
	title: string;
	leadText: string;
	text: string;
	buttonText?: string;
    buttonText2?: string;
	image: any;
	darkMode?: boolean;
    anchorId?: string;
    link?: any;
    link2?: any;
    contactPopup?: boolean;
    smallImage?: boolean;
}

/**
 *
 * This component displays an image and some text. The image is displayed on the left or right side based on the
 * imageLeft boolean that can be passed to this component.
 */
// eslint-disable-next-line
export default (props: IImageTextBox) => (

    <div className={`row no-gutters smallScreenPadding ${!props.imageLeft && 'flex-row-reverse'}`} id={props.anchorId}>
        <div className={`col-lg-6 ${props.darkMode ? 'darkMode' : ''}`}>
            {props.smallImage ?
                <img style={{ padding: '3em' }} src={props.image} alt='Happy greenhouse' width='100%' height='100%' />
                :
                <img src={props.image} alt='Happy greenhouse' width='100%' height='100%' />
            }
		</div>

		<div className='col-lg-6 align-self-center smallScreenMargins'>
			<div className='d-flex justify-content-center'>
				<div className='row' style={{width: '100%'}}>
                    <div className='col-sm-12 offset-sm-0 col-md-10 offset-md-1 col-lg-8 offset-lg-2'>
                        <h1 className='display-4'>{props.title}</h1>
                        <h2><small className='text-muted'>{props.leadText}</small></h2>
						<p className='marginTop1'>{props.text}</p>
                        { props.link &&
                            <button
                                className='btn btn-primary btn-lg marginTop1 mr-2'
                                style={{backgroundColor: '#000763', borderColor: '#000763'}}
                                onClick={props.link ? () => props.link() : undefined}
                            >
                                {props.buttonText}
                            </button>
                        }
                        { props.link2  &&
                            <button
                                className='btn btn-success btn-lg marginTop1 mr-2'
                                onClick={props.link2 ? () => props.link2() : undefined}
                            >
                                {props.buttonText2}
                            </button>
                        }
                        {props.contactPopup &&
                            <ContactPopup />
                        }
					</div>
				</div>
			</div>
		</div>
	</div>
);
