import React from 'react';
import './styles.css';

import * as projects from './projects.json';
/*
import datapoints from '../../assets/images/datapoints.jpg';
import health from '../../assets/images/health.jpg';
import ml from '../../assets/images/ml.png';
*/
import A1computervision from './articles/A1computervision';
import A2AmbientAssistedLiving from './articles/A2AmbientAssistedLiving';
import A3Forecasting from './articles/A3Forecasting';
// import A2AAl from './articles/A2AAL';
interface IProject {
	id: number;
	name: string;
	text: string;
	image: string;
	navigation: any;
}

// const images = [undefined, datapoints, health, ml];

export default class ProjectDetails extends React.Component<IProject> {

	public state = {
		project: undefined,
	};

	componentWillReceiveProps(newProps) {
        if (this.props.navigation.getParam('id', 0) !== newProps.navigation.getParam('id', 0)) {
            this.setStoryState(newProps.navigation.getParam('id', 0));
        }
    }

	componentDidMount(): void {
		const id = this.props.navigation.getParam('id', 0);
		this.setStoryState(id);
	}

	setStoryState(id: number) {
		const findProject = projects.Projects.filter( (result) => result.id === Number(id) )[0];
		this.setState({project: findProject});
	}

	public render() {

		const project: any = this.state.project;

		if (!this.state.project) {
			return (
				<div className='row justify-content-center'>
					<div>Loading...</div>
				</div>
			);
		}

		return (
			<div>
				<div className='jumbotron jumbotron-fluid project-details-jumbo'
					 style={{marginBottom: 0, backgroundSize: 'cover', backgroundPosition: 'center' }}>
					<div className='container d-table' style={{height: '100%'}}>
						<div className='text-center d-table-cell align-middle'>
							<div className='jobHeader project-details-jumbo-header' style={{ color: 'black' }}>{}</div>
						</div>
					</div>
				</div>

				{(() => {switch (project.id) {
					case 1: return <A1computervision {...this.props} />;
					case 2: return  <A2AmbientAssistedLiving {...this.props}/>;
					case 3: return  <A3Forecasting {...this.props}/>;
					default: return <div></div>;
				}})()}

			</div>
		);
	}
}
