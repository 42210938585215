/* eslint-disable import/no-anonymous-default-export */

export default (props?: any) => {
    console.log(props);
    return (
        <div>
        <div className='container'>
        <div className='project-title-text'>
            <h2>Familielid jan</h2>
        </div>
        <div className='body-text-div'><p>
        Jan was altijd een bezorgde zoon geweest. Hij had een goede relatie met zijn moeder, maar toen hij voor zijn werk naar het buitenland verhuisde, maakte hij zich zorgen over haar welzijn. Zijn moeder woonde alleen en had geen andere familieleden in de buurt.

            </p></div>
            <div className='body-text-div'><p>
            Jan maakte zich vooral zorgen over hoe zijn moeder omging met haar medicijnen en dagelijkse routines. Hij wilde er zeker van zijn dat ze goed verzorgd werd, zelfs als hij er niet was. Gelukkig kwam hij erachter over ons product en besloot hij het voor zijn moeder te installeren.
         </p></div>
            <div className='body-text-div'><p>
            Na het installeren van de app op de televisie van zijn moeder, kon Jan haar dagelijks op afstand monitoren. Hij kon nu zien of ze haar medicijnen op tijd innam en of ze haar dagelijkse routines volgde. Dit gaf hem veel gemoedsrust, omdat hij wist dat zijn moeder nu in goede handen was.
 </p></div>
            <div className='body-text-div'><p>
            Maar de grootste verrassing kwam toen zijn moeder ontdekte hoe gemakkelijk het was om met hem te communiceren via de app. Ze hadden nu dagelijks contact en konden elkaar via de videochat zien en spreken alsof ze naast elkaar zaten. Jan was blij om te zien dat zijn moeder nu minder eenzaam was en dat ze echt genoot van het gebruik van de app.
</p></div>
            <div className='body-text-div'><p>
            Door ons product hoefde Jan zich geen zorgen meer te maken over zijn moeder en kon hij zich volledig concentreren op zijn werk. Hij wist dat zijn moeder nu in goede handen was en dat hij er altijd voor haar kon zijn wanneer ze hem nodig had. Het gaf hem een gevoel van gemoedsrust en verbondenheid met zijn moeder, zelfs op afstand.


        </p></div></div>
        </div>
    );

};
