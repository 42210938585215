

import './styles.css';
/*
import plants from './../../assets/images/plants.jpg';
import img_cv from '../../assets/images/computer_vision.png';
import img_pm from '../../assets/images/predictive_maintance.jpg';
import img_ar from '../../assets/images/ar.jpg';

import img_ml from '../../assets/images/ml.png';
import img_vi from '../../assets/images/vi.jpg';
const imgs = [plants, img_cv, img_pm, img_ar, img_ml, img_vi];
*/

interface IProjectCard {
	title: string;
	text: string;
	imageNumber: number;
	onClick: () => {};
}

// eslint-disable-next-line
export default (props: IProjectCard) => {
	return (
		<div className='row no-gutters animated fadeIn fast justify-content-md-center'>
			<div className='animated fadeInLeft'>
				<div className='project-card-size project-card-shadow'>
					{/*<div style={{ height: '250px'}}>
						<img src={imgs[props.imageNumber]} alt='plants' height='100%' width='100%' style={{objectFit: 'cover'}} />
					</div>*/}
					<div className='project-card-text-indent' style={{ minHeight: '12rem'}}>
						<h3>{props.title}</h3>
						<p>{props.text}</p>
						<button className='btn btn-outline-dark 'style={{ width: '100%', color: 'white', backgroundColor: '#000764', margin: 0, position: 'absolute', bottom: '15px', right: 0 }}	onClick={() => props.onClick()}> {'lees meer'}	</button>
					</div>
				</div>
			</div>
		</div>
	);
};
