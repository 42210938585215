import ImageTextBox from '../../Components/ImageTextBox/ImageTextBox';
import WhatWeDo from '../../Components/WhatWeDo/WhatWeDo';
import LOC from '../../Localization/Translations';
import dthinking from './../../assets/images/coho_eenzaam.jpg';
import plego from './../../assets/images/coho_vrienden.jpg';
import plants from './../../assets/images/coho_living_room.jpg';
import tff from './../../assets/images/coho3.jpg';
import road from './../../assets/images/coho2.jpg';
import active from './../../assets/images/coho_active.jpg';
import contact from './../../assets/images/coho_contact.jpg';
import thuis from './../../assets/images/coho_thuis.jpg';
import logo from './../../assets/images/Mtek512x512.png';
// import cv from './../../assets/images/computer_vision.png';

import { Helmet } from 'react-helmet';
import GoogleAnalytics from '../../Util/analytics/GoogleAnalytics.web';
import './styles.css';
import Carrousel from '../../Components/Carrousel/Carrousel';
import { Carousel } from 'react-responsive-carousel';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine,  faCompressArrowsAlt,  faSearchPlus, faTachometerAlt } from '@fortawesome/free-solid-svg-icons';

// eslint-disable-next-line
export default (props?: any) => {
    const lang = LOC.getLanguage();

    const jsonld2 = [
        {
            '@type': 'Service',
            'serviceType': lang.prototyping,
            'description': lang.prototypingIntro,
            'url': `https://www.${lang.domain}/services`,
            '@id': `https://www.${lang.domain}/services/1`,
            'Provider': {
                '@type': 'Organization',
                'name': lang.name,
            },
        },
        {
            '@type': 'Service',
            'serviceType': lang.strategy,
            'description': lang.strategyIntro,
            'url': `https://www.${lang.domain}/services`,
            '@id': `https://www.${lang.domain}/services/2`,
            'Provider': {
                '@type': 'Organization',
                'name': lang.name,
            },
        },
        {
            '@type': 'Service',
            'serviceType': lang.continiousdevelopment,
            'description': lang.continiousdevelopmentIntro,
            'url': `https://www.${lang.domain}/services`,
            '@id': `https://www.${lang.domain}/services/3`,
            'Provider': {
                '@type': 'Organization',
                'name': lang.name,
            },
        },
        {
            '@type': 'Service',
            'serviceType': lang.innovationlaunch,
            'description': lang.innovationlaunchIntro,
            'url': `https://www.${lang.domain}/services`,
            '@id': `https://www.${lang.domain}/services/4`,
            'Provider': {
                '@type': 'Organization',
                'name': lang.name,
            },
        },
    ];
    const jsonld = {
        '@context': 'http://schema.org/',
        '@type': 'Webpage',
        'name': lang.name,
        'image': logo,
        'description': 'Software protototyping, strategy, and development',
        'url': `https://www.${lang.domain}`,
    };
    GoogleAnalytics.getInstance().hit(props.navigation.state.key);

    const arrowStyles: React.CSSProperties = {
        position: 'absolute',
        zIndex: 2,
        top: 'calc(50% - 15px)',
        fontSize: 30,
        fontWeight: 900,
        color: 'white',
        cursor: 'pointer',
        textShadow: `rgb(0 0 0) 1px 0px 10px`,
    };

    const bannerThingies = [
        // Wat is de ergenis / probleem
        // afvragen bij tekst wat zou het nou echt betekenen
        {
            title: 'Zorg op afstand was nog nooit zo eenvoudig',
            text: 'Met ons product kunt u op een veilige en gemakkelijke manier contact opnemen met de zorginstelling en uw zorgverleners. Probeer het vandaag nog en ontdek hoe u de zorgervaring kunt verbeteren.',
            img: plants,
        },
        {
            title: 'Altijd in verbinding met de zorginstelling', // 'Buiten de gebaande paden',
            text: 'ontdek hoe ons product u kan helpen bij het verbeteren van de communicatie met de zorginstelling en het gemakkelijk beheren van medicatie en dagelijkse routines', // 'Waar standaardoplossingen tekortschieten, bouwen wij verder.',
            img: contact,
        },
        {
            title: 'Blijf op de hoogte van uw dagelijkse routine',
            text: 'ons product biedt dagritmebewaking, waardoor u nooit een afspraak of medicatie mist. Ontdek hoe wij u kunnen helpen om meer controle te krijgen over uw dagelijks leven', // 'Wij zetten AI technieken in op kwaliteitscontrole voor de hoogst mogelijke On-Time In-Full.',
            img: road,
        },
        {
            title: 'Houd contact met uw dierbaren',
            text: 'onze app biedt niet alleen ondersteuning bij de zorg, maar stelt u ook in staat om met uw familieleden te bellen. Probeer het vandaag nog en ontdek hoe u in contact kunt blijven met de mensen die u liefhebt.',
            img: tff,
        },
        {
            title: 'Blijf actief en gezond',
            text: 'met onze app kunt u gemakkelijk uw activiteiten bijhouden en ondersteund worden in het actief blijven bewegen. Ontdek hoe ons product u kan helpen om een gezonde en actieve levensstijl te behouden.',
            img: active,

        },
    ];

    return (
        <div>
            <Helmet>
                <title> {lang.name} - Home </title>
                <link rel='canonical' href={`https://www.${lang.domain}`}/>
                <script className='structured-data-list' type='application/ld+json'>{JSON.stringify(jsonld)}</script>
                <script className='structured-data-list' type='application/ld+json'>{JSON.stringify(jsonld2)}</script>
            </Helmet>
            <div>
                <Carousel showArrows={true} showThumbs={false} dynamicHeight={false}
                infiniteLoop
                autoPlay
                interval={4000}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && (
                        <p  onClick={onClickHandler} title={label} style={{ ...arrowStyles, left: 15 }}>
                            {`<`}
                        </p>
                    )
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && (
                        <p onClick={onClickHandler} title={label} style={{ ...arrowStyles, right: 15 }}>{`>`}</p>
                    )
                }>
                    {bannerThingies.map(b => (b.title === 'animatie' ?
                        <div style={{height: '60vh', backgroundColor: 'white', backgroundSize: 'cover', backgroundPosition: 'center' }}>
                            <div className='' style={{height: '100%'}}>
                                <video autoPlay muted loop style={{ width: '100%', height: '100%', objectFit: 'contain'}}>
                                    <source src='https://maketek.s3.amazonaws.com/1_Final.MP4' type='video/mp4' style={{ minWidth: '100%'}} />
                                </video>
                            </div>
                        </div>
                    :
                        <div style={{height: '60vh', backgroundImage: `url(${b.img})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                            <div className='' style={{textAlign: 'start', position: 'absolute', bottom: '10%', right: '0%', backgroundColor: 'rgb(0 25 100 / 86%)', paddingTop: 20, paddingBottom: 20, paddingRight: 60, paddingLeft: 60}}>
                                <h3 className='display-4 no-opacity' style={{color: '#FFFFFF', fontWeight: 500, textShadow: 'rgb(50 50 50) 1px 1px 6px', fontSize: '3vw' }}>{b.title}</h3>
                                <p className='lead' style={{color: '#FFFFFF', textShadow: '#000000 2px 2px 10px' }}>{b.text} </p>
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>
            {/*

            <div className='landingBackground'>
                <div className='jumbotron jumbotron-fluid jumbo-custom'>
                    <div className='container container-custom d-table'>
                        <div className='d-table-cell align-middle'>
                            <h3 className='display-4 no-opacity' style={{ color: '#FFFFFF', fontWeight: 600, textShadow: '#000000 1px 0 10px' }}>{lang.slogan}</h3>
                            <p className='lead' style={{ color: '#FFFFFF', textShadow: '#000000 1px 0 10px' }}>{lang.subSlogan} </p>

                            {/* Alternatively: */}
                            {/*<p className='lead'>{LOC.current.subSlogan}</p>*/}
                            {/*<span className='btn btn-outline-light rounded-button'>{lang.startHere}</span>*/}
                        {/*</div>
                    </div>
                </div>
            </div>
            */}
            <WhatWeDo />
            <ImageTextBox
                imageLeft
                title={lang.designThinking}
                leadText=''
                text={lang.designThinkingText}
                buttonText={lang.designThinkingButton}
                image={thuis}
                smallImage
                // link={() => props.navigation.navigate('Services', {id: 'analyse'})}
                contactPopup={true}
            />

            <ImageTextBox
                imageLeft={false}
                title={lang.tekGears}
                leadText=''
                text={lang.tekGearsText}
                buttonText={lang.tekGearsButton}
                image={plego}
                smallImage
                // link={() => props.navigation.navigate('Services', {id: 'prototype'})}
                contactPopup={true}
            />

            <ImageTextBox
                imageLeft={true}
                title={lang.innovationlaunch}
                leadText=''
                text={lang.innovationlaunchIntro}
                buttonText={lang.innovationlaunchButton}
                image={dthinking}
                smallImage
                // link={() => props.navigation.navigate('Services', {id: 'prototype'})}
                contactPopup={true}
            />


            <div className='padding-bottom-50 padding-top-50'>
                    <h1 className='text-center home-header'>{'Wat zijn onze sterke punten'}</h1>
            </div>
            <div className='container' >
                <div className='row no-gutters'>
                    <div className='col-sm-12 col-md-6 leftBox box'>
                        <div className='media'>
                            <div className='media-left left-offset-40 right-offset-40'>
                                <FontAwesomeIcon icon={faSearchPlus} size='4x' color='#4E8DA6' style={{minWidth: 80}}/>
                            </div>
                            <div className='media-body media-text right-offset-40'>
                                <h3>{`Communicatie`} </h3>
                                {}
                                <br/>
                            </div>
                        </div>
                    </div>

                    <div className='col-sm-12 col-md-6 box'>
                        <div className='media'>
                            <div className='left-offset-40 right-offset-40'>
                                <FontAwesomeIcon icon={faCompressArrowsAlt} size='4x' color='#4E8DA6' style={{minWidth: 80}}/>
                            </div>
                            <div className='media-body media-text right-offset-40'>
                                <h3>{`Zelfstandigheid`} </h3>
                                {}
                                <br/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row no-gutters'>
                    <div className='col-sm-12 col-md-6 leftBox bottomBox box'>
                        <div className='media'>
                            <div className='media-left left-offset-40 right-offset-40'>
                                <FontAwesomeIcon icon={faTachometerAlt} size='4x' color='#4E8DA6' style={{minWidth: 80}}/>
                            </div>
                            <div className='media-body media-text right-offset-40'>
                                <h3> {`Toegang tot technologie en samenleving`} </h3>
                                {}
                                <br/>
                            </div>
                        </div>
                    </div>

                    <div className='col-sm-12 col-md-6 bottomBox box'>
                        <div className='media'>
                            <div className='media-left right-offset-40 left-offset-40'>
                                <FontAwesomeIcon icon={faChartLine} size='4x' color='#4E8DA6' style={{minWidth: 80}}/>
                            </div>
                            <div className='media-body media-text right-offset-40'>
                                <h3>{`Ondersteuning in gezond leven`}</h3>
                                {}
                                <br/>
                                { /*<span className='btn btn-primary color-white align-self-center custom-bottom'> {lang.innovationlaunchButton} </span> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='padding-bottom-50 padding-top-50 orangeBackground'>
                <h1 className='text-center home-header'>{'Ervaringen'}</h1>

            </div>

           {Carrousel(props, 3, '')}
{/*
            <ImageTextBox
                imageLeft
                title={lang.projectWd}
                leadText={lang.projectWdLead}
                text={lang.projectWdText}
                buttonText={lang.projectWdButton}
                image={plants}
            /> {/*link={ () => props.navigation.navigate('ProjectDetails', {id: 1}) }*/}
            {/*}
            <ImageTextBox
                imageLeft={false}
                title={lang.projectTff}
                leadText={lang.projectTffLead}
                text={lang.projectTffText}
                buttonText={lang.projectTffButton}
                image={tff}
            /> {/*link={() => props.navigation.navigate('ProjectDetails', { id: 2 })}*/}
        </div>
    );
};
