/* eslint-disable import/no-anonymous-default-export */
export default (props?: any) => {
    console.log(props)
    return (
        <div>
            <div className='container'>
                <div className='project-title-text'>
                    <h2>Domotica in zorginnovatie</h2>
                </div>
                <div className='body-text-div'><p>
                    Er is tegenwoordig steeds meer wens voor ouderen om zo lang mogelijk zelfstandig te kunnen blijven wonen. Ook de zorgverleners zijn hierbij gebaat om de stijgende zorgkosten te beperken.
                    Een van de oplossingen voor dit vraagstuk is “Ambient Assisted Living” (AAL). AAL zet moderne technologieën in om ouderen te assisteren in het dagelijks leven.
                    AAL technieken variëren van een draagbare alarm knop tot zelflerende algoritmen die afwijkingen in levensstijl kunnen detecteren op basis van sensoren en automatisch alarm kunnen slaan.
                    AAL technologieën zijn een middel waarmee zorgverleners effectiever ingezet kunnen worden, maar het blijft wenselijk om voldoende contact te behouden tussen zorgverlener en cliënt, eventueel kan dit op afstand.
                    Specifiek het contact op afstand is waar wij kansen voor verbetering zien.
                    </p>
                </div>
                <div className='body-text-div'><p>

                    Het is geen geheim dat het voor ouderen niet altijd even makkelijk is om nieuwe technologieën te adopteren.
                    Veel producten gericht op senioren proberen smartphones en tablets voor hen bruikbaar te maken door het inzetten van een sterk versimpelde user interface.
                    Wij zien meer in een andere aanpak die doorontwikkelt op een apparaat dat voor cliënten over het algemeen al een stuk meer bekend is; de televisie.

                    Onze oplossing is een klein kastje dat aan de tv wordt aangesloten. Het kastje kan met de televisie interageren en zo nodig automatisch het kanaal van de tv naar het kastje wisselen. Dit kastje is ook in verbinding met een draadloze webcam die videocalls mogelijk maakt.
                    Het kastje zal het voor de zorgverlener mogelijk maken op een simpele manier contact te zoeken met de cliënt door via het kastje een videocall te initiëren. De cliënt kan met de afstandsbediening van de tv de oproep accepteren. De cliënt kan auditief, visueel of haptisch op de hoogte gesteld worden dat er wordt gebeld.
                </p></div>
                <div className='body-text-div'><p>
                    Er zijn een aantal concepten en technologieën die aan het fundament van dit systeem liggen. <br/>
                    Het eerste is de thuisvrij box, de basis voor het kastje dat het centrale punt is voor het videobellen, ten tweede de user interface en als laatste het data verzamelen voor AAL. De tv box is een kleine, single-board computer waarop vaak Linux wordt gedraaid. Het voordeel hiervan is dat alle software die voor de box wordt ontwikkeld eventueel ook op een ander platform dat Linux ondersteunt gedeployed zou kunnen worden, mocht dat wenselijk zijn. De tv box aantrekkelijk geprijsd en uitbreidbaar met een groot aantal extensies, bijvoorbeeld een 5G module.
                </p></div>
                <div className='body-text-div'><p>
                    De interactie tussen het kastje en de tv is mogelijk door middel van het Consumer Electronics Control (CEC) protocol. Dit protocol staat apparaten toe om over HDMI met elkaar en de tv te communiceren. Zo kunnen apparaten bijvoorbeeld de tv aanzetten, het kanaal veranderen of de invoer vanuit de afstandsbediening uitlezen. Met CEC is het dus mogelijk om het aantal vereiste stappen voor het maken van een oproep sterk te verminderen.
                    Ondersteunend aan CEC heeft Linux een library die keyboard en mouse inputs kan simuleren, genaamd xdotool. Met deze library kunnen we een mapping maken van afstandsbediening input naar keyboard inputs, waardoor we de UI kunnen bouwen op keyboard inputs.
                </p></div>
                <div className='body-text-div'><p>
                    De basis voor het videobellen is WebRTC. WebRTC is een standaard voor real-time, peer-2-peer communicatie via een browser die door alle grote browsers worden ondersteund. Ook zijn er native clients voor android en iOS. WebRTC kan gebruikt worden om video, audio en andere data te versturen. Door WebRTC te gebruiken zijn er geen afhankelijkheden op externe videobel services. Dit is vanwege de potentieel gevoelige informatie die besproken wordt een pluspunt voor de privacy van de gebruikers. WebRTC werkt ook cross-platform dus is er nooit het probleem dat bellen niet mogelijk is omdat er ergens een ander platform wordt gebruikt.
                    De combinatie van bovenstaande drie punten maakt een systeem waar de cliënten zonder complexe leertrajecten toch toegang krijgen tot technologieën die voor hen tot noch toe onbruikbaar waren.
                    Wil je meer weten over dit project, of meer infomatie over hoe automatisering voor jouw bedrijf ingezet kan worden, neem contact met ons op.

                


                </p></div>

            </div>
        </div>
    );

};
